var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { extractToken, logoutAdmin } from '@/assets/ts/helpers/admin';
import { CustomEventName } from '@/assets/ts/enums/custom-event-name.enum';
const API_URL = 'https://adsel.pl/server';
// const API_URL = 'http://localhost:8080';
// const API_URL = 'https://adsel.pl/server/index.php';
// import {extractToken} from '@/assets/ts/helpers/admin';
// const API_URL = 'http://localhost:8080';
// const API_URL = 'https://adsel.pl/server/index.php';
// const PRE_PROD_API_URL = 'https://vps-8b115655.vps.ovh.net/server/api';
// const PROD_API_URL = 'https://adsel.pl/server/api';
const handleRequest = (response, rejectHandler) => __awaiter(void 0, void 0, void 0, function* () {
    const parsedResponse = yield response.json();
    if (response.status === 400) {
        rejectHandler(parsedResponse.msg || 'Błąd');
    }
    else if (response.status === 401) {
        logoutAdmin();
        document.dispatchEvent(new CustomEvent(CustomEventName.Unauthorized));
        rejectHandler();
    }
    return parsedResponse;
});
const getRequest = (url, adminStrict = false) => new Promise((resolve, reject) => {
    fetch(adminStrict ? `${url}?${new URLSearchParams({ token: extractToken() })}` : url, { mode: 'cors' }).then((response) => handleRequest(response, reject))
        .then((results) => resolve(results))
        .catch((error) => {
        console.error(error);
        reject(error);
    });
});
function postRequest(url, postData, adminStrict = false) {
    const headersToAppend = getDefaultHeaders();
    if (adminStrict) {
        headersToAppend.set('Token', extractToken());
    }
    return new Promise((resolve, reject) => {
        fetch(url, {
            mode: 'cors',
            method: 'POST',
            headers: headersToAppend,
            body: JSON.stringify({ data: postData })
        }).then((response) => handleRequest(response, reject))
            .then((results) => resolve(results))
            .catch((error) => {
            console.error(error);
            reject(error);
        });
    });
}
function putRequest(url, putData, adminStrict = false) {
    const headersToAppend = getDefaultHeaders();
    if (adminStrict) {
        headersToAppend.set('Token', extractToken());
    }
    return new Promise((resolve, reject) => {
        fetch(url, {
            mode: 'cors',
            method: 'PUT',
            headers: headersToAppend,
            body: JSON.stringify({ data: putData })
        }).then((response) => handleRequest(response, reject))
            .then((results) => resolve(results))
            .catch((error) => {
            console.error(error);
            reject(error);
        });
    });
}
function deleteRequest(url, adminStrict = false) {
    const headersToAppend = getDefaultHeaders();
    if (adminStrict) {
        headersToAppend.set('Token', extractToken());
    }
    return new Promise((resolve, reject) => {
        fetch(url, {
            mode: 'cors',
            method: 'DELETE',
            headers: headersToAppend
        })
            .then(response => response.json())
            .then(results => {
            resolve(results);
        })
            .catch((error) => {
            console.error(error);
            reject(error);
        });
    });
}
function getDefaultHeaders() {
    return new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    });
}
export { API_URL, getRequest, postRequest, deleteRequest, putRequest };
