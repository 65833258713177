import { defineComponent, watch } from 'vue';
import * as c3 from 'c3';
export default defineComponent({
    components: {},
    props: {
        achievementsCountByType: {
            type: Object,
            required: false,
            default: null
        }
    },
    setup(props) {
        watch(() => props.achievementsCountByType, (newState) => {
            if (newState) {
                c3.generate({
                    bindto: '#achievementCountChart',
                    data: {
                        columns: [
                            ['Startowe', props.achievementsCountByType.count[1]],
                            ['Główne', props.achievementsCountByType.count[2]],
                            ['Normalne', props.achievementsCountByType.count[3]],
                            ['Trudne', props.achievementsCountByType.count[4]],
                            ['Pracochłonne', props.achievementsCountByType.count[5]],
                        ],
                        type: 'pie',
                        colors: {
                            'Startowe': '#28A745BF',
                            'Główne': '#58AAFF',
                            'Normalne': 'rgba(228, 96, 109, 0.75)',
                            'Trudne': '#DC3545BF',
                            'Pracochłonne': 'rgba(189, 33, 48, 0.75)'
                        }
                    },
                    tooltip: {
                        contents: (pieElementData) => {
                            return `<div class="c3-tooltip c3-tooltip-pie">${pieElementData[0].name}: ${pieElementData[0].value}</div>`;
                        }
                    }
                });
            }
        });
    }
});
