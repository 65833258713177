import { defineComponent, watch } from 'vue';
import * as c3 from 'c3';
export default defineComponent({
    components: {},
    props: {
        achievementsTopPlayers: {
            type: Array,
            required: false,
            default: []
        }
    },
    setup(props) {
        watch(() => props.achievementsTopPlayers, (newState) => {
            if (newState) {
                const players = newState.map((topPlayer) => topPlayer.player_id);
                const achievementsCount = newState.map((topPlayer) => topPlayer.total);
                console.log('top10winners', players, achievementsCount);
                c3.generate({
                    bindto: '#achievementTopChart',
                    data: {
                        columns: [
                            [
                                'Zaliczonych osiągnięć',
                                ...achievementsCount
                            ]
                        ],
                        type: 'bar'
                    },
                    axis: {
                        x: {
                            type: 'category',
                            categories: players
                        }
                    },
                    padding: {
                        bottom: 20
                    },
                });
            }
        });
    }
});
