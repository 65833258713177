import { defineComponent } from 'vue';
export default defineComponent({
    components: {},
    props: {
        lastLotteryWinners: {
            type: Array,
            required: false,
            default: null
        }
    },
    setup() {
        return {};
    }
});
