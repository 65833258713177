import { defineComponent, watch } from 'vue';
import * as c3 from 'c3';
export default defineComponent({
    components: {},
    props: {
        lastAchievements: {
            type: Array,
            required: false,
            default: []
        }
    },
    setup(props) {
        watch(() => props.lastAchievements, (newState) => {
            if (!newState) {
                return;
            }
            const config = {
                bindto: '#achievementChart',
                data: {
                    columns: [
                        [
                            'Ukończonych osiągnięć',
                            ...newState.map((data) => data.total)
                        ]
                    ],
                },
                axis: {
                    x: {
                        type: 'category',
                        categories: newState.map((data) => data.label)
                    }
                },
                padding: {
                    bottom: 20
                },
                point: {
                    r: 8
                }
            };
            c3.generate(config);
        });
        return {};
    }
});
