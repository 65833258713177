import { LOCAL_STORAGE_KEY_LOGGED_ADMIN, LOCAL_STORAGE_KEY_LOGGED_ADMIN_TOKEN } from '@/assets/ts/variables/auth';
function getLoggedAdmin() {
    const loggedAdminString = localStorage.getItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN);
    if (loggedAdminString && loggedAdminString !== 'undefined') {
        return JSON.parse(loggedAdminString);
    }
    return null;
}
function logoutAdmin() {
    localStorage.removeItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN);
    localStorage.removeItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN_TOKEN);
    location.reload();
}
function getLoggedAdminId() {
    const loggedAdmin = getLoggedAdmin();
    return loggedAdmin ? parseInt(loggedAdmin.administrator_id) : null;
}
function extractToken() {
    const loggedAdminToken = localStorage.getItem(LOCAL_STORAGE_KEY_LOGGED_ADMIN_TOKEN);
    if (!loggedAdminToken) {
        return '';
    }
    const tokenObject = JSON.parse(loggedAdminToken);
    return (tokenObject === null || tokenObject === void 0 ? void 0 : tokenObject.token) ? tokenObject.token : '';
}
export { getLoggedAdmin, getLoggedAdminId, logoutAdmin, extractToken };
