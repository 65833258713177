import AdminPanelLastAchievements from '@/components/panel/panel-dashboard/charts/AdminPanelLastAchievements.vue';
import AdminPanelTopAchievements from '@/components/panel/panel-dashboard/charts/AdminPanelTopAchievements.vue';
import AdminPanelAchievementsCount from '@/components/panel/panel-dashboard/charts/AdminPanelAchievementsCount.vue';
import AdminPanelLastLotteryWinners from '@/components/panel/panel-dashboard/charts/AdminPanelLastLotteryWinners.vue';
import { getDashboardStats } from '@/assets/ts/api/dashboard';
import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
    components: {
        AdminPanelLastLotteryWinners,
        AdminPanelAchievementsCount,
        AdminPanelTopAchievements,
        AdminPanelLastAchievements
    },
    setup() {
        const savedNicknamesCount = ref(0);
        const savedPlayersCount = ref(0);
        const achievementsCountByType = ref({});
        const lastLotteryWinners = ref([]);
        const lastAchievementsCount = ref([]);
        const achievementsTopPlayers = ref([]);
        onMounted(() => {
            getDashboardStats().then((response) => {
                savedNicknamesCount.value = response.nicknames_count;
                savedPlayersCount.value = response.players_count;
                achievementsCountByType.value = response.achievements_count_by_type;
                lastLotteryWinners.value = response.last_lottery_winners;
                achievementsTopPlayers.value = response.achievements_top;
                lastAchievementsCount.value = response.last_achievements;
            }).catch((error) => {
                console.error(error);
            });
        });
        return {
            achievementsCountByType,
            achievementsTopPlayers,
            lastAchievementsCount,
            lastLotteryWinners,
            savedNicknamesCount,
            savedPlayersCount
        };
    }
});
